import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faPhone,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, NavLink, Outlet } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import VerticalLine from "../components/VerticalLine";
import RedirectLink from "../components/RedirectLink";
import ContainerIcon from "../components/ContainerIcon";
import logo_academia from "../assets/img/logo_academia.svg";
import "./style.css"

export default function Root() {
  return (
    <>
      <div className="section-head d-none d-md-block">
        <Container className="py-3">
          <Row className="justify-content-center justify-content-lg-between">
            <Col md="auto">
              <Row className="text-center text-md-start">
                <Col md="auto">
                  <p className="mb-0">Academia Nacional de Bellas Artes</p>
                </Col>
                <VerticalLine className="my-md-0" colorLine="#646464" />
                <Col md="auto" className="my-md-0 my-1">
                  <a href="tel:541148022469">
                    <FontAwesomeIcon icon={faPhone} /> (54) (11) 4802-2469 /
                    3490 / 8413
                  </a>
                </Col>
                <VerticalLine className="my-md-0" colorLine="#646464" />
                <Col md="auto">
                  <a href="mailto:info@anba.org.ar">
                    <FontAwesomeIcon icon={faEnvelope} /> info@anba.org.ar
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md="auto" className="mt-lg-0 mt-3">
              <Row
                id="row-sociales"
                className="justify-content-center justify-content-md-start"
              >
                <Col xs="auto">
                  <a href="https://www.facebook.com/anbaweb" Target="_blank">
                    {" "}
                    <FontAwesomeIcon icon={faFacebookF} />{" "}
                  </a>
                </Col>
                <Col xs="auto">
                  <a href="https://twitter.com/anbaweb" Target="_blank">
                    {" "}
                    <FontAwesomeIcon icon={faTwitter} />{" "}
                  </a>
                </Col>
                <Col xs="auto">
                  <a href="https://www.instagram.com/anba_ar/" Target="_blank">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section-head-bottom d-none d-md-block">
        <Container className="py-md-3">
          <Row>
            <Col md="3" className="align-self-center">
              <Link to={"/"}>
                <img className="w-100" src={logo_academia} alt="logo.png" />
              </Link>
            </Col>
            <Col md="5" lg="4" className="pe-lg-0 align-self-center">
              <Row className="justify-content-lg-end mx-lg-0">
                <Col md="3" lg="auto" className="align-self-center">
                  <ContainerIcon icon={faMapMarkerAlt} width={37} height={37} />
                </Col>
                <Col md="9" xl="auto" className="pe-lg-0">
                  <p className="mb-0">Sánchez de Bustamante 2663, 2º Piso</p>
                  <p className="mb-0">(C1425DVA) CABA Argentina</p>

                </Col>
              </Row>
            </Col>
            <Col md="4" lg="5" className="align-self-center">
              <Row>
                <Col className="pe-lg-0">
                  <Row className="justify-content-lg-end">
                    <Col md="9" xl="auto">
                      <p className="mb-0">Atención al público:</p>
                      <p className="mb-0">Martes, miércoles y jueves de 13 a 18 hs.</p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md="5"
                  className="ps-lg-4 align-self-center d-none d-lg-block"
                >
                  <Button
                    type="button"
                    variant="secondary"
                    className="button-buscar"
                    onClick={() => {
                      window.location.href = "/buscador";
                    }}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="menu-container d-block d-lg-none bg-gris-oscuro">
        <Navbar expand="lg">
          <Container className="justify-content-md-end">
            <Link
              to={"/"}
              className="logocontainerheadermobile navbar-brand d-md-none d-lg-block"
            >
              <img
                className="w-70"
                src="../../images/logo_light.png"
                alt="logo.png"
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="w-100 justify-content-between">
                <NavLink to={"/"} className="nav-link text-uppercase">
                  Inicio
                </NavLink>
                <NavLink
                  to={"/sobre-nosotros"}
                  className="nav-link text-uppercase"
                >
                  Institucional
                </NavLink>
                <NavLink to={"/academicos"} className="nav-link text-uppercase">
                  Académicos
                </NavLink>
                <NavLink
                  to={"/fondo-patrimonial"}
                  className="nav-link text-uppercase"
                >
                  Fondo Patrimonial
                </NavLink>
                <a
                  href={"http://181.104.121.61/"}
                  Target="_blank"
                  className="nav-link text-uppercase"
                >
                  biblioteca
                </a>
                <NavLink
                  to={"/publicaciones"}
                  className="nav-link text-uppercase"
                >
                  publicaciones
                </NavLink>
                <NavLink to={"/noticias"} className="nav-link text-uppercase">
                  noticias
                </NavLink>
                <NavLink to={"/hitos"} className="nav-link text-uppercase">
                  Línea de tiempo
                </NavLink>
                <NavLink
                  to={"/instituciones-relacionadas"}
                  className="nav-link text-uppercase"
                >
                  INSTITUCIONES RELACIONADAS
                </NavLink>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="menu-container bg-dark-opacity position-absolute w-100 d-none d-lg-block">
        <Navbar expand="lg" className="bg-dark-opacity p-0">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="w-100 justify-content-between">
                <NavLink to={"/"} className="nav-link text-uppercase">
                  Inicio
                </NavLink>
                <NavLink
                  to={"/sobre-nosotros"}
                  className="nav-link text-uppercase"
                >
                  Institucional
                </NavLink>
                <NavLink
                  to={"/academicos?tipo=Académicos de número"}
                  className="nav-link text-uppercase"
                >
                  Académicos
                </NavLink>
                <NavLink
                  to={"/fondo-patrimonial"}
                  className="nav-link text-uppercase"
                >
                  Fondo Patrimonial
                </NavLink>
                <a
                  href={"http://181.104.121.61/"}
                  Target="_blank"
                  className="nav-link text-uppercase"
                >
                  biblioteca
                </a>
                <NavLink
                  to={"/publicaciones"}
                  className="nav-link text-uppercase"
                >
                  publicaciones
                </NavLink>
                <NavLink to={"/noticias"} className="nav-link text-uppercase">
                  noticias
                </NavLink>
                <NavLink to={"/hitos"} className="nav-link text-uppercase">
                  Línea de tiempo
                </NavLink>
                <NavLink
                  to={"/instituciones-relacionadas"}
                  className="nav-link text-uppercase"
                >
                  INSTITUCIONES RELACIONADAS
                </NavLink>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div id="content-wrapper">
        <Outlet />
      </div>
      <footer>
        <Container className="pb-md-5">
          <Row className="justify-content-center">
            <Col xs="7" md="5" lg="4" xl="3" className="mt-5">
              <img
                className="w-100 px-md-3 px-lg-4 px-xl-3"
                src="../../images/logo_light.png"
                alt="logo_light.png"
              />
            </Col>
          </Row>
          <Row className="justify-content-center newsletter mt-5 mt-md-4 pt-md-3">
            <Col
              sm="auto"
              className="align-self-center text-center text-md-start"
            >
              <p className="m-0">Deja tu correo para recibir noticias</p>
            </Col>
            <VerticalLine className="my-md-1 d-none d-md-block" />
            <Col
              md="3"
              className="align-self-center ps-md-0 text-center text-md-start"
            >
              <InputGroup>
                <Form.Control
                  placeholder="correo electrónico"
                  className="border-0"
                  type="email"
                />
                <InputGroup.Text
                  id="icon-input-mail"
                  className="border-0 bg-transparent"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col sm="12" className="d-block d-md-none pt-0">
              <hr />
            </Col>
          </Row>
          <Row className="justify-content-center mb-md-4 pb-md-3 pt-md-2 d-none d-md-flex">
            <Col sm="6" md="8" lg="6">
              <hr />
            </Col>
          </Row>
          <Row className="mb-md-4 pb-md-1 pt-md-0 pt-5">
            <Col md="4" className="mb-5 mb-md-0">
              <h6 className="mb-md-4 pb-md-2">Académicos</h6>
              <h5 className="mb-md-3 pb-md-1">Buscar por especialidades</h5>
              <p className="mb-md-3 pb-md-1 pe-md-5">
                Arquitectura y diseño <br />
                Artes Visuales
                <br />
                Historia y crítica del arte
                <br />
                Música
              </p>
              <RedirectLink
                to={"/academicos"}
                onClick={() => {
                  window.scroll(0, 0);
                }}
                text={"Buscar académicos"}
              />
            </Col>
            <Col md="4" className="mb-5 mb-md-0">
              <h6 className="mb-md-4 pb-md-2">Institucional</h6>
              <div className="d-flex justify-content-between me-xl-4 pe-xl-2">
                <ul className="p-0 m-0">
                  <li>
                    <HashLink smooth to="/sobre-nosotros/#MesaDirectiva">
                      Mesa Directiva
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/estatuto">
                      Estatuto
                    </HashLink>
                  </li>
                  <li>
                    <HashLink smooth to="/#historia">
                      Historia
                    </HashLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/noticias"}
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      Noticias
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/hitos"}
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      Línea de tiempo
                    </NavLink>
                  </li>
                </ul>
                <ul className="p-0 m-0">
                  <li>
                    <HashLink smooth to="/fundacion-trabucco#top">
                      {" "}
                      Fundación Trabucco
                    </HashLink>
                  </li>
                  <li>
                    <a href="https://www.fundacionfjklemm.org/" Target="_blank">
                      {" "}
                      Fundación Klemm
                    </a>
                  </li>
                  <li>
                    <a href="http://186.23.210.29/" Target="_blank">
                      Biblioteca
                    </a>
                  </li>
                  <li>
                    <NavLink
                      to={"/publicaciones"}
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      Publicaciones
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="4" lg="3" className="ms-auto pb-5 pb-md-0">
              <h6 className="mb-md-4 pb-md-2">Contacto</h6>
              <Row className="d-flex d-md-none">
                <Col xs="auto">
                  <Row>
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faPhone} className="iconinfo" />
                    </Col>
                    <Col xs="auto" className="align-self-center">
                      <p className="mb-0">+(11) 4802-2469 / 3490 / 8413</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <Row>
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faEnvelope} className="iconinfo" />
                    </Col>
                    <Col xs="auto" className="align-self-center">
                      <p className="mb-0">info@anba.org.ar</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto" className="mt-2">
                  <Row>
                    <Col xs="auto">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="iconinfo"
                      />
                    </Col>
                    <Col xs="auto" className="align-self-center">
                      <p className="mb-0">Sánchez de Bustamante 2663,</p>
                      <p className="mb-0">2° Piso (C1425DVA)</p>
                      <p className="mb-0">CABA Argentina</p>
                      <p class="mb-0">Atención al público:</p>
                      <p class="mb-0">
                        Martes, miércoles y jueves de 13 a 18 hs.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="d-none d-md-flex">
                <Col xs="auto">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="iconinfo" />
                </Col>
                <Col xs="auto" className="align-self-center">
                  <p className="mb-0">Sánchez de Bustamante 2663,</p>
                  <p className="mb-0">2° Piso (C1425DVA)</p>
                  <p className="mb-0">CABA Argentina</p>
                  <p class="mb-0">Atención al público:</p>
                  <p class="mb-0">Martes, miércoles y jueves de 13 a 18 hs.</p>
                </Col>
              </Row>
              <Row className="d-none d-md-flex my-3">
                <Col xs="auto">
                  <FontAwesomeIcon icon={faPhone} className="iconinfo" />
                </Col>
                <Col xs="auto" className="align-self-center">
                  <p className="mb-0">+(11) 4802-2469 / 3490 / 8413</p>
                </Col>
              </Row>
              <Row className="d-none d-md-flex">
                <Col xs="auto">
                  <FontAwesomeIcon icon={faEnvelope} className="iconinfo" />
                </Col>
                <Col xs="auto" className="align-self-center">
                  <p className="mb-0">info@anba.org.ar</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <section className="legales py-4">
          <Container className="py-md-1">
            <Row className="justify-content-center justify-content-md-between">
              <Col xs="auto" className="align-self-center mb-md-0 mb-3">
                <small>
                  Academia Nacional de Bellas Artes &copy;{" "}
                  {new Date().getFullYear()}
                </small>
              </Col>
              <Col xs="auto">
                <Row>
                  <Col xs="auto">
                    <div className="container-icon">
                      <a
                        className="linkToPage"
                        href="https://www.facebook.com/anbaweb"
                        Target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </div>
                  </Col>
                  <Col xs="auto" className="px-md-1">
                    <div className="container-icon">
                      <a
                        className="linkToPage"
                        href="https://twitter.com/anbaweb"
                        Target="_blank"
                      >
                        <FontAwesomeIcon icon={faTwitter} />{" "}
                      </a>
                    </div>
                  </Col>
                  <Col xs="auto">
                    <div className="container-icon">
                      <a
                        className="linkToPage"
                        href="https://www.instagram.com/anba_ar/"
                        Target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faInstagram} />{" "}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </footer>
    </>
  );
}
