import React, { useEffect, useState } from "react";
import "./style.css";
import { REST_URL } from "./../../config/env";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import PremiosCard from "../../components/PremiosCard/PremiosCard";
import premios from "./../../utilities/Premios";

export default function Premios() {
  const [textI, settextI] = useState("");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    textIntroductorio();
  }, []);

  async function textIntroductorio() {
    await fetch(REST_URL + "/datosControlerOBDA", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((textoI) => {
        setloading(false);
        settextI(textoI[2].texto_introductorio);
      });
  }

  return (
    <section id="FondoDocumental">
      <article className="portada">
        <img
          className="imagenPortada"
          src={require("../../assets/img/Bg_hitos.png")}
          alt="Sala de plenario"
        />

        <div className="position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center">
          <h3 className="text-uppercase mb-md-3" id="tituloInstitucional">
            Premios
          </h3>
          <p className="text-uppercase mb-md-3" id="tituloInstitucional">
            {textI}
          </p>
        </div>
      </article>
    </section>
  );
}
